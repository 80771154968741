import React, { useEffect, useState } from "react";
import "./App.css";
import { getToken } from "./Services/AuthToken";
import GetToken from "./GetToken";
import SearchUi from "./SearchUi";

const App = () => {
  const [apiToken, setApiToken] = useState("");

  const checkToken = () => {
    const token = getToken();
    if (token === null || token.length === 0) {
      setApiToken("");
    } else {
      setApiToken(token);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div className="main-container">
      {apiToken.length === 0 && <GetToken onAddToken={checkToken} />}
      {apiToken.length > 0 && <SearchUi apiToken={apiToken} />}
    </div>
  );
};

export default App;
