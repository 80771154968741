import React, { useState } from "react";
import axios from "axios";
import { SearchResult, SearchResponse } from "./Services/Types";
import ResultTable from "./ResultTable";

const searchUrl = "https://remarkable-search.seancarpenter.net/v1/search";

type Props = {
  apiToken: string;
};

const SearchUi = ({ apiToken }: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);

  const onSearch = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get<SearchResponse>(searchUrl, {
        params: { search },
        headers: { "x-api-key": apiToken },
      });
      setResults(result.data.pages);
      setSubmitted(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  let buttonClass = "ui primary button";
  if (search.length < 2) {
    buttonClass = `${buttonClass} disabled`;
  }
  if (isLoading) {
    buttonClass = `${buttonClass} loading`;
  }

  return (
    <>
      <h1 className="ui header">Search Notes</h1>
      <div className="ui large icon input">
        <input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
        <i className="search icon" />
      </div>
      <button
        className={buttonClass}
        onClick={onSearch}
        style={{ marginLeft: "0.25rem" }}
        disabled={search.length < 2}
      >
        Search
      </button>
      {submitted && <ResultTable results={results} />}
    </>
  );
};

export default SearchUi;
