import React, { useState } from "react";
import { setToken } from "./Services/AuthToken";

type Props = {
  onAddToken: () => void;
};

const GetToken = ({ onAddToken }: Props) => {
  const [tokenValue, setTokenValue] = useState("");

  const onSave = () => {
    setToken(tokenValue);
    onAddToken();
  };

  return (
    <>
      <h1 className="ui header">Add an API Token</h1>
      <div style={{ marginBottom: "0.5rem" }}>
        There is no API token available.
      </div>
      <div className="ui input">
        <input
          type="text"
          placeholder="API token"
          value={tokenValue}
          onChange={({ target }) => setTokenValue(target.value)}
        />
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <button className="ui primary button" onClick={onSave}>
          Save
        </button>
      </div>
    </>
  );
};

export default GetToken;
