import React from "react";
import { SearchResult } from "./Services/Types";

type Props = {
  results: SearchResult[];
};

const ResultTable = ({ results }: Props) => {
  if (results.length === 0) {
    return <div>No matching pages</div>;
  }

  const rows = results.map((r, index) => <ResultRow result={r} key={index} />);
  return (
    <table className="ui striped table">
      <thead>
        <tr>
          <th>Page Name</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

type RowProps = {
  result: SearchResult;
};

const ResultRow = ({ result }: RowProps) => {
  return (
    <tr>
      <td>{result.page}</td>
    </tr>
  );
};

export default ResultTable;
