const tokenName = "remarkable-ui-token";

const getToken = (): string | null => {
  try {
    const token = window.localStorage.getItem(tokenName);
    return token;
  } catch (err) {
    console.error(err);
    return "";
  }
};

const setToken = (token: string): void => {
  try {
    window.localStorage.setItem(tokenName, token);
  } catch (err) {
    console.error(err);
  }
};

export { getToken, setToken };
